
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

@media only screen and (max-width: 1918px) {

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.85rem 1.43rem;
  }

  .left-side-bar .table-dark span.icn-box {
    top: 17px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 11px .70rem 11px;
  }

  .orders-sec .group-price-bock.style-two {
    padding: 8px 0 4px;
  }

  .inner-form-block ul.limint-btn {
    padding: 5px 0 15px;
  }

  .form-sell-buy .form-area input[type="text"] {
    margin-bottom: 20px;
  }

  .form-check-inline {
    margin-top: 26px;
    margin-bottom: 30px;
  }

  .check {
    top: 8px;
  }

  .form-check-inline label.form-check-label {
    line-height: 3em;
  }

  .form-sell-buy input[type="submit"] {
    padding: 15px 10px;
  }
}

@media only screen and (max-width: 1800px) {
  .orders-sec .group-price-bock {
    margin-right: 10px;
  }

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.86rem 1.23rem;
  }

  /* .order-detail .inner-col {
    padding: 134px 0;
  } */
}

@media only screen and (max-width: 1690px) {

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.66rem 1.03rem;
  }

  .graph-sec .top-area ul li {
    margin-right: 24px;
  }

  .form-sell-buy ul.rate-area {
    display: block;
    margin: 12px 0px 13px;
  }

  .nav-tabs .nav-item {
    /* padding-right: 10px; */
  }

  /* .order-detail .inner-col {
    padding: 154px 0;
  } */
}

@media only screen and (max-width: 1680px) {

  .left-side-bar .table {
    height: 40vh;
}

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.5rem 1.03rem;
  }

  .left-side-bar .table-dark span.icn-box {
    top: 12px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 6px .70rem 6px;
  }

  .orders-sec .group-price-bock.style-two {
    padding: 17px 0 9px;
  }

  .form-check-inline {
    margin-top: 3px;
    margin-bottom: 0;
    width: 18.5%;
  }

  .form-sell-buy input[type="submit"] {
    padding: 8px 10px;
  }

  .inner-form-block ul.limint-btn {
    padding: 5px 0 0;
  }

  /* .order-detail .inner-col {
    padding: 54px 0;
  } */
}

@media only screen and (max-width: 1600px) {
  
  .left-side-bar .table {
    height: 63vh;
}

  .monyback-sec {
    background-position: left 52px;
  }

  .form-sell-buy .form-area input[type="text"] {
    margin-bottom: 8px;
    padding: 3px 15px;
  }

  .nav-tabs .nav-item {
    /* padding-right: 7px; */
  }

  .left-side-bar .table td, .left-side-bar .table th {
      padding: 0.1rem 0.88rem;
  }
 
  .left-side-bar .table-dark span.icn-box {
    top: 6px;
  }

  .graph-sec .top-area ul li {
    margin-right: 40px;
  }

  /* .order-detail .inner-col {
    padding: 24px 0;
  } */

  .inner-form-block ul.limint-btn {
    padding: 0;
  }

  .form-sell-buy .form-check-inline .form-check-input:before {
    width: 42px;
  }

  .form-sell-buy input[type="submit"] {
    padding: 7px;
    margin: 0;
  }

  .form-sell-buy .form-check-inline .form-check-input {
    margin-right: 25px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 0px .70rem 1px;
  }

  .orders-sec .group-price-bock.style-two {
    padding: 18px 0 14px;
    margin-right: 0;
  }

  .form-sec-buy .top-area {
    display: none;
  }

  .form-sell-buy ul.rate-area {
    display: block;
    margin: 7px 0px 16px;
  }

  .form-check-inline {
    margin-top: 0px;
    margin-bottom: 17px;
  }

  .form-sec-buy {
    padding: 20px 15px;
    /* width: 40.3vw; */
  }

  .form-sell-buy .form-area p {
    margin-bottom: 0;
  }

  .form-check-inline label.form-check-label {
    line-height: 2em;
  }

  .check {
    top: 3px;
}
}

@media only screen and (max-width: 1550px) {

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.20rem 0.77rem;
  }

  .graph-sec .top-area ul li {
    margin-right: 15px;
  }

  /* .order-detail .inner-col {
    padding: 30px 0;
  } */
}

@media only screen and (max-width: 1440px) {

  .graph-sec .graph-area {
    height: 86%;
}
  .left-side-bar .table {
    height: 72vh;
}

  .date-sec ul li a {
    padding: 5px 16px;
  }

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.20rem 0.40rem;
  }

  .left-side-bar .table-usdt td,
  .left-side-bar .table-usdt th {
    padding: 0.46rem 0.50rem !important;
  }
  .orders-sec {
      height: 42vh;
      position: relative;
      /* width: 40.3vw; */
  }
  .graph-sec .top-area ul li {
    margin-right: 27px;
  }

  .history-sec ul.orderlist li a {
    margin-right: 2px;
  }

  .date-sec select.mdb-select.md-form {
    margin-top: 15px;
    float: none;
  }

  .orders-sec .group-price-bock ul.grap-btn li {
    padding: 1px 5px;
  }

  .orders-sec .group-price-bock.style-two {
    padding: 17px 0 7px;
  }

  .inner-form-block ul.limint-btn li a {
    padding: 5px 11px;
    margin-right: 2px;
  }

  .form-sell-buy .form-check-inline .form-check-input {
    margin-right: 14px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 6px 0.7rem 6px;
  }

  .form-check-inline {
    margin-top: 11px;
    margin-bottom: 28px;
  }

  .form-check-inline label.form-check-label {
    line-height: 3em;
  }

  .inner-form-block ul.limint-btn li {
    display: inline-block;
    margin-top: 10px;
  }

  .form-sell-buy ul.rate-area {
    margin: 10px 0px 11px;
  }

  .form-sell-buy ul.rate-area li:first-child {
    margin-right: 38px;
  }

  .left-side-bar .table-dark span.icn-box {
    top: 8px;
    right: -4px;
  }

  /* .order-detail .inner-col {
    padding: 36px 0;
  } */

  .form-sell-buy .form-area p {
    margin-bottom: 14px;
  }

  .check {
    top: 7px;
  }
  .form-sell-buy ul.rate-area {
      margin: 5px 0px 8px;
  }
  .form-sell-buy .form-area input[type="number"] {
      display: inline-block;
      width: 60%;
      background-color: #1b1b27;
      border: 1px solid #414152;
      color: #fff;
      padding: 5px 15px;
      margin-bottom: 8px;
      font-size: 12px;
  }
  .form-check-inline {
      margin-top: 11px;
      margin-bottom: 8px;
  }
  .graph-sec {
      height: 56%;
      margin-bottom: 8px;
  }
  .orders-sec .group-price-bock {
      margin-right: 10px;
  }

  .history-sec{
    height: 40%;
  }
}


@media only screen and (max-width: 1400px) {
  .graph-sec .top-area ul li {
    margin-right: 24px;
  }

  .left-side-bar .table {
    height: 63vh;
}

  /* .order-detail .inner-col {
    padding: 26px 0;
  } */

  .form-sell-buy .form-area p {
    margin-bottom: 0;
  }

  .form-sec-buy .top-area {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .inner-form-block ul.limint-btn {
    padding: 0 0;
  }

  .form-check-inline {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .form-check-inline label.form-check-label {
    line-height: 2em;
  }

  .check {
    top: 1px;
  }
}

@media only screen and (max-width: 1366px) {

  .left-side-bar .table {
    height: 57vh;
}

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.04rem 0.15rem 0.04rem;
    line-height: 2.3em;
  }

  .left-side-bar .table-usdt td,
  .left-side-bar .table-usdt th {
    padding: 0.46rem 0.30rem !important;
  }

  .form-sec-buy {
    padding: 8px 14px;
  }

  .left-side-bar {
    height: 97%;
  }

  .left-side-bar .table-dark span.icn-box {
    top: 4px;
    right: -5px;
  }

  .form-sell-buy input[type="submit"] {
    padding: 3px;
    margin: 0;
  }

  .form-sec-buy {
    height: 65%;
  }

  .graph-sec .top-area ul li {
    margin-right: 21px;
  }

  .graph-sec {
    /* height: 70%; */
  }

  .graph-sec .graph-area {
    height: 85%;
  }

  .history-sec ul.orderlist li a {
    padding: 4px 6px;
  }

  .date-sec ul li a {
    padding: 5px 15px;
  }

  .orders-sec .group-price-bock ul.grap-btn li img {
    width: 13px;
  }

  .orders-sec .group-price-bock ul.grap-btn li {
    padding: 0px 3px;
  }

  .orders-sec .group-price-bock.style-two {
    padding: 14px 0 3px;
  }

  .inner-form-block {
    width: 46%;
    display: inline-block;
    position: relative;
    margin-right: 16px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 0px .40rem 0px;
  }

  .left-side-bar .nav-tabs .nav-link {
    padding: .2rem 0.7rem;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .form-sell-buy .form-check-inline .form-check-input {
    margin-right: 15px;
  }

  .form-sell-buy ul.rate-area {
    margin: 1px 0px 2px;
  }

  .orders-sec .group-price-bock {
    padding: 5px 0 0;
  }

  .inner-form-block ul.limint-btn li {
    margin-top: 3px;
  }

  .form-sell-buy input[type="submit"] {
    margin-top: -1px;
  }

  .form-sell-buy .form-check-inline .form-check-input {
    margin-right: 13px;
  }

  .form-check-inline {
    margin-top: 0;
    margin-bottom: 3px;
    width: 18%;
  }

  .form-sell-buy .form-area input[type="text"] {
    margin-bottom: 5px;
    padding: 1px 15px;
  }

  .inner-form-block ul.limint-btn li a {
    padding: 3px 11px;
    margin-right: 2px;
  }

  .inner-form-block ul.limint-btn li .mdb-select {
    padding: 3px;
  }

  .form-check-inline label.form-check-label {
    line-height: 23px;
  }

}
@media (max-height: 768px) and (max-width: 1366px){
  .left-side-bar .table td, .left-side-bar .table th {
      padding: 0.04rem 0.40rem 0.04rem; 
  }
  .orders-sec .group-price-bock {
      margin-right: 10px;
      height: 223px;
   
  }
  .orders-sec {
      height: 50%;
      position: relative;
  }
  .form-sec-buy {
      height: 52%;
  }
  .left-side-bar {
      height: 91%;
  }
  section.admin-area {
      position: relative;
      height: 87vh;
  }
  .table-area .table-dark td, .table-area .table-dark th, .table-area .table-dark thead th {
      padding: 6px 0.5rem 6px;
  }
}
@media only screen and (max-width: 1310px) {
  .graph-sec .top-area ul li {
    margin-right: 15px;
  }

  /* .graph-sec {
    height: 70%;
  }

  .graph-sec .graph-area {
    height: 88%;
  } */

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.04rem 0.07rem 0.04rem;
  }

  .left-side-bar .table-usdt td,
  .left-side-bar .table-usdt th {
    padding: 0.46rem 0.22rem !important;
  }

  .left-side-bar .table-dark span.icn-box {
    top: 3px;
    right: -4px;
  }

  .date-sec ul li a {
    padding: 5px 12px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 0px .35rem 0px;
  }

  .form-sec-buy {
    padding: 10px 15px;
  }

  .form-sell-buy input[type="submit"] {
    margin-top: 8px;
    padding: 3px;
  }
}

@media only screen and (max-width: 1280px) {

  .left-side-bar .table {
    height: 46vh;
}

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.26rem 0.3rem;
  }

  .left-side-bar .table-usdt td,
  .left-side-bar .table-usdt th {
    padding: 0.46rem 0.15rem !important;
  }

  .left-side-bar .nav-tabs .nav-link {
    padding: 10px;
  }

  .graph-sec .top-area ul li i {
    display: block;
  }

  .history-sec ul.orderlist li a {
    margin-right: 0px;
    padding: 4px 3px;
  }

  .date-sec ul li a {
    padding: 5px 4px;
  }

  .orders-sec .group-price-bock ul.grap-btn li img {
    width: 13px;
  }

  .orders-sec .group-price-bock ul.grap-btn li {
    padding: 0px 5px;
  }

  .graph-sec .top-area ul li {
    margin-right: 14px;

  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 0px .40rem;
  }

  .form-sell-buy ul.rate-area li:first-child {
    margin-right: 22px;
  }

  .form-sell-buy .form-check-inline .form-check-input {
    margin-right: 8px;
  }

  .form-sell-buy .form-check-inline .form-check-input:before {
    width: 38px;
  }

  .form-sell-buy .form-area input[type="text"] {
    margin-bottom: 5px;
  }

  .form-sec-buy {
    padding: 5px 15px;
  }

  .form-sec-buy .top-area {
    margin-bottom: 5px;
    padding-bottom: 7px;
  }

  .orders-sec .group-price-bock .group-btn select.mdb-select.md-form {
    padding: 1px;
  }

  /* .form-sec-buy {
    height: 53%;
  } */

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 0px .20rem 0;
  }

  .orders-sec .group-price-bock.style-two {
    padding: 14px 0 0;
  }

  .left-side-bar .table-dark span.icn-box {
    top: 6px;
    right: -3px;
  }

  /* .order-detail .inner-col {
    padding: 16px 0;
  } */

  .form-sell-buy .form-area input[type="text"] {
    margin-bottom: 3px;
    padding: 2px 15px;
  }

  .input-check-box {
    margin-left: 11px;
  }

  .inner-form-block ul.limint-btn li a {
    padding: 5px 11px;
    margin-right: 2px;
    font-size: 10px;
  }

  .orders-sec .group-price-bock.style-two .table-dark.table-striped tbody tr:nth-child(7),
  .orders-sec .group-price-bock.style-two .table-dark.table-striped tbody tr:nth-child(8) {
    display: none;
  }

  .orders-sec .group-price-bock.style-two .table-dark td,
  .orders-sec .group-price-bock.style-two .table-dark th,
  .orders-sec .group-price-bock.style-two .orders-sec .group-price-bock.style-two thead th {
    padding: 2px 0.4rem 1px;
  }

  .form-sell-buy input[type="submit"] {
    padding: 0px;
    margin: 0;
  }

  .form-check-inline {
    margin-top: 0;
    margin-bottom: 0px;
    width: 18%;
  }
}
@media (max-height: 800px) and (max-width: 1280px){
  .left-side-bar {
      height: 98%;
  }
  .graph-sec {
      height: 63%;
      margin-bottom: 8px;
  }
  .orders-sec .group-price-bock.style-two .table-dark td, .orders-sec .group-price-bock.style-two .table-dark th, .orders-sec .group-price-bock.style-two .orders-sec .group-price-bock.style-two thead th {
      padding: 4px 0.5rem 4px;
  }
  .table-area .table-dark td, .table-area .table-dark th, .table-area .table-dark thead th {
      padding: 4px .20rem 4px;
  }
  .orders-sec {
      height: 41%;
      position: relative;
  }
  .orders-sec .group-price-bock {
      margin-right: 10px;
      height: 260px;
    
  }
  .left-side-bar .table td, .left-side-bar .table th {
      padding: 0.26rem 0.29rem;
  }
}
@media only screen and (max-width: 1215px) {
 
  .orders-sec {
    height: 54%;    
  }

}

@media only screen and (max-width: 1200px) {

  .left-side-bar .table {
    height: 75vh;
}

  .page-wrapper-inner {
    height: auto;
  }

  .orders-sec .group-price-bock {
    width: 48%;
  }

  .orders-sec .group-price-bock .group-btn select.mdb-select.md-form {
    font-size: 11px;
    padding: 1px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 0px .18rem;
  }

  .orders-sec .group-price-bock ul.grap-btn li img {
    width: 11px;
  }

  .form-sell-buy .form-check-inline .form-check-input {
    margin-right: 3px;
  }

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.26rem 0.8rem;
  }

  .left-side-bar .table-usdt td,
  .left-side-bar .table-usdt th {
    padding: 0.46rem 1rem !important;
  }

  .form-check {
    padding-left: 0.25rem;
  }

  .history-sec ul.orderlist li a {
    padding: 4px 3px;
    font-size: 10px;
  }

  .date-sec ul li a {
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 5px;
  }

  .orders-sec .group-price-bock.style-two {
    padding: 9px 0 3px;
  }

  .orders-sec .group-price-bock {
    padding: 2px 0 0;
  }

  .form-sell-buy ul.rate-area li:first-child {
    margin-right: 12px;
  }

  .left-side-bar .nav-tabs .nav-link {
    padding: 7px;
  }

  .date-sec ul li a {
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 3px;
  }

  .graph-sec .top-area ul li {
    margin-right: 8px;
  }

  .inner-form-block {
    width: 45%;
  }

  .form-sell-buy ul.rate-area li:first-child {
    margin-right: 5px;
  }

  .form-sell-buy .form-check-inline .form-check-input {
    margin-right: 1px;
  }

}
@media (max-height: 720px) and (max-width: 1200px){
  section.admin-area {
      position: relative;
      height: 85vh;
  }
  .orders-sec .group-price-bock {
      margin-right: 10px;
      height: 225px;
   
  }
  .orders-sec {
      display: flex;
      height: 41%;
  }
  .form-sec-buy {
      height: 56%;
  }
  .form-sell-buy button[type="button"]{
    margin-top: 20px;
  }
  .left-side-bar .table td, .left-side-bar .table th {
      padding: 0.26rem 0.1rem;
  }
  .left-side-bar .table {
      height: 69vh;
  }
}
@media only screen and (max-width: 1199px){
  section.admin-area {
      position: relative;
      height: auto;
  }
  .left-side-bar .table td, .left-side-bar .table th {
      padding: 0.26rem 1.2rem;
  }
  .right-side-bar .table-column.col-xl-6.col-lg-12{
    padding-right: 15px;
    padding-left: 15px;
  }
  .orders-sec .group-price-bock {
      width: 49%;
      float: left;
  }
}
@media only screen and (max-width: 1139px) {
  .banner-section .image-column {
    margin-top: 0;
  }
  section.admin-area {
    position: relative;
      height: auto;
  }
  .banner-section .content-box {
    padding: 206px 0 58px 8%;
  }

  .nav-tabs .nav-link {
    margin-right: 62px;
  }

  .banner-section.style-two .content-box {
    padding: 165px 40px 0 40px;
  }

  .banner-section.style-two .image-column {
    margin-top: 0;
  }

  .coins-box {
    margin-top: 45px;
  }

  .coins-box .coin-inner {
    width: 21%;
    margin-bottom: 60px;
  }

  .why-choose-us .service-outer .service-inner .title-area h2 {
    font-size: 24px;
  }

  .account-sec .content-column .inner-clomun .title-area h3 {
    font-size: 32px;
  }

  .account-sec {
    height: auto;
    padding: 100px 0 100px;
  }

  .left-side-bar {
    position: relative;
    height: 100%;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 0px 1.2rem;
  }

  .bottom-inner-area {
    position: relative;
    width: 100%;
  }

  .main-menu .navigation>li {
    margin-right: 5px;
  }

  .graph-sec .top-area ul li {
    margin-right: 52px;
  }

  .left-side-bar .table-dark span.icn-box {
    top: 6px;
    right: 1px;
  }

  .graph-sec {
      height: 400px;
  }

  .history-sec {
    height: auto;
  }

  .history-sec ul.orderlist li a {
    margin-right: 18px;
    padding: 4px 12px;
  }

  .orders-sec {
    position: relative;
    margin-top: 10px;
    height: auto;
  }

  .orders-sec .group-price-bock {
    width: 49%;
    padding: 10px 10px 10px;
  }

  .orders-sec .group-price-bock.style-two {
    padding: 17px 0 15px;
  }

  .form-sec-buy {
    padding: 15px 15px;
    margin-bottom: 10px;
    height: auto;
  }

  .nav-tabs .nav-link {
    margin-right: 15px;
  }

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.26rem 0.52rem;
  }

  .left-side-bar .table td, 
  .left-side-bar .table th {
    padding: 0.26rem 1.02rem;
}
.left-side-bar .table{
  height: 100%;
}
  .form-sec-buy .top-area {
    margin-bottom: 0;
    padding-bottom: 0;
    display: none;
  }

  .inner-form-block ul.limint-btn {
    padding: 0 0 15px;
  }
}

@media only screen and (min-width: 768px) {

  .main-menu .navigation>li>ul,
  .main-menu .navigation>li>.mega-menu,
  .main-menu .navigation>li>ul>li>ul {
    display: block !important;
    visibility: hidden;
    opacity: 0;
  }

}
@media only screen and (max-width: 1024px){
  .left-side-bar .table td, .left-side-bar .table th {
      padding: 0.26rem 0.72rem;
  }
}
@media only screen and (max-width: 1023px) {
  .sticky-header {
    display: none !important;
  }
  
}
@media only screen and (max-width: 992px){
  .left-side-bar .table td, .left-side-bar .table th {
    padding: 0.26rem 0.65rem;
}
}
@media only screen and (max-width: 991px) {
  .main-header .header-upper .logo-box {
    float: none;
    text-align: center;
  }
  .submit-auth-btn {
    padding: 15px 25px;
}
  .balnce-table-sec table {
    width: 1024px;
}
  .main-menu .navigation>li {
    margin-right: 0px;
  }

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.26rem 1.35rem;
  }

  .left-side-bar .table-usdt td,
  .left-side-bar .table-usdt th {
    padding: 0.46rem 1.4rem !important;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 0px 0.6rem;
  }

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.40rem 1.35rem;
  }
    .table-striped td a {
      width: 90px !important;
      display: inline-block;
      text-align: center;
  }

  .orders-sec .group-price-bock {
    margin-right: 10px;
    height: auto;
    min-height: 260px;
    overflow-y: auto;
}
.footer-widget {
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
}
.graph-sec .top-area .coin-blance-area {
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
}
.graph-sec .top-area .coin-blance-area .coin-img-area {
  display: block;
  align-items: center;
  width: auto;
  margin-right: 40px;
}
.orders-sec .group-price-bock {
  width: 100%;
  padding: 10px 10px 10px;
  margin-bottom: 10px;
}
}


@media only screen and (max-width: 768px) {
  .nav-tabs .nav-link {
    margin-right: 20px;
    margin-bottom: 15px;
  }
  .order-detail .table-area table {
    width: 671px;
}
  .history-sec ul.orderlist li a {
    margin-right: 4px;
    padding: 4px 8px;
  }

  .right-check-area {
    float: none;
    margin-left: 38px;
    margin-top: 10px;
    margin-right: 0;
  }

  .date-sec select.mdb-select.md-form {
    margin-top: -29px;
    float: right;
  }

  .date-sec ul li {
    margin-top: 10px;
  }

  .orders-sec .group-price-bock {
    width: 100%;
    padding: 10px 0 10px;
    margin-bottom: 10px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 2px 2.2rem 1px;
  }

  .orders-sec .group-price-bock.style-two .table-dark td,
  .orders-sec .group-price-bock.style-two .table-dark th,
  .orders-sec .group-price-bock.style-two .orders-sec .group-price-bock.style-two thead th {
    padding: 2px 2.2rem 1px;
  }

  .inner-form-block {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .form-check-inline {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 16%;
  }

  .form-sell-buy .form-check-inline .form-check-input:before {
    width: 73px;
  }

  .nav-inner .right-area {
    position: relative;
    float: right;
    margin-right: 0;
    padding-bottom: 14px;
  }



  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.26rem 0.5rem;
  }

  .left-side-bar .table-usdt td,
  .left-side-bar .table-usdt th {
    padding: 0.46rem 0.65rem !important;
  }
  .orders-sec .group-price-bock.style-two .table-dark td, 
  .orders-sec .group-price-bock.style-two .table-dark th, 
  .orders-sec .group-price-bock.style-two .orders-sec .group-price-bock.style-two thead th,
  .table-area .table-dark td, .table-area .table-dark th, .table-area .table-dark thead th {
      padding: 2px 0.4rem 1px;
  }
  .left-side-bar .table td, .left-side-bar .table th {
      padding: 0.26rem 0.7rem;
  }
  
}

@media only screen and (max-width: 767px) {

  .main-header .logo-box .logo {
    padding: 15px 0;
  }
  .navbar-toggler {
    padding: 2px 6px !important;
    font-size: 14px !important;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}
  .left-side-bar .nav-tabs .nav-link{
    padding: 7px 23px ;
  }
  .nav-inner .right-area ul li a {
    padding: 18px 15px;
}
.services-box .services-block {
  min-height: auto !important;
}
.services-box .services-block .text-column {
  display: block;
  text-align: center;
}
.services-box .services-block .text-column .p1 {
  padding-bottom: 8px;
}
.services-box .services-block h3 {
  margin-top: 28px;
}
  .inner-header .nav-outer {
    float: right;
    z-index: 10;
    position: relative;
    margin-left: 0;
    margin-top: 11px;
    width: 100%;
}
.navbar-light .navbar-toggler {
  color: #a80382 !important;
    border-color: #a80382 !important;
  position: absolute;
  right: 11px;
  top: 3px;
  z-index: 9;
}

.navbar-toggler .icon-bar{
  background-color:#a80382;
}
  .main-header .main-menu {
    padding-top: 0px;
    width: 100%;
    margin: 0px;
  }
  .inner-header .header-upper .nav-inner {
    position: relative;
    padding: 0 1%;
}
  .main-menu .navbar-header {
    position: relative;
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    padding: 8px 0px;
    right: 0px;
    z-index: 9;
  }

  .navbar-toggler {
    padding: 0;
  }

  .navbar-toggler .icon {
    position: relative;
    font-size: 28px;
    line-height: 43px;
    color: #ff8a00;
    border: 0;
    outline: 0;
  }

  .navbar-toggler .icon:before {
    margin: 0;
    font-size: 28px;
    line-height: 43px;
  }

  .main-menu .collapse {
    max-height: 400px;
    overflow: auto;
    float: none;
    width: 100%;
    padding: 10px 0px 0px;
    border: none;
    margin: 0;
    border-radius: 0px;
    box-shadow: none;
    margin-top: 0;
  }

  .main-menu .collapse.in,
  .main-menu .collapsing {
    padding: 10px 0px 0px;
    border: none;
    margin: 0px 0px 15px;
    -ms-border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    box-shadow: none;
  }

  .navbar-dark .navbar-toggler {
    border: 1px solid #ffffff !important;
  }

  .navbar-light .navbar-toggler {
    border: 1px solid #fff;
  }

  .main-menu .navbar-collapse>.navigation {
    float: none !important;
    margin: 0px !important;
    width: 100% !important;

    border: 1px solid #ffffff;
    border-top: none;
  }

  .main-menu .navbar-collapse>.navigation>li {
    margin: 0px !important;
    float: none !important;
    padding: 0px !important;
    width: 100%;
    background: #000;
  }

  .main-menu .navigation>li>a,
  .main-menu .navigation>li>ul:before {
    border: none;
  }

  .main-menu .navbar-collapse>.navigation>li>a {
    padding: 10px 10px !important;
    border: none !important;
    font-size: 15px !important;
  }

  .main-menu .navigation li.dropdown>a:after,
  .main-menu .navigation>li.dropdown>a:before,
  .main-menu .navigation>li>ul>li>a::before,
  .main-menu .navigation>li>ul>li>ul>li>a::before {
    color: #ffffff !important;
    right: 15px;
    font-size: 16px;
    display: none !important;
  }
  .left-sidebar {
    padding: 0 15px !important;
}
.right-side-bar .graph-column.col-xl-6.col-lg-12,
.right-side-bar .table-column.col-xl-6.col-lg-12 {
  padding: 0 15px;
}
  .main-menu .navbar-collapse>.navigation>li>ul,
  .main-menu .navbar-collapse>.navigation>li>ul>li>ul {
    position: relative;
    border: none;
    float: none;
    visibility: visible;
    opacity: 1;
    display: none;
    margin: 0px;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    padding: 0px;
    outline: none;
    width: 100%;
    background: #333333;
    -webkit-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    transition: none !important;
    -webkit-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    -moz-transition: none !important;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -moz-transform: scaleY(1);
    transform: scaleY(1);
  }

  .main-menu .navbar-collapse>.navigation>li>ul,
  .main-menu .navbar-collapse>.navigation>li>ul>li>ul {
    border-top: 1px solid rgba(255, 255, 255, 1) !important;
  }

  .main-menu .navbar-collapse>.navigation>li,
  .main-menu .navbar-collapse>.navigation>li>ul>li,
  .main-menu .navbar-collapse>.navigation>li>ul>li>ul>li {
    border-top: 1px solid rgba(255, 255, 255, 1) !important;
    border-bottom: none;
    opacity: 1 !important;
    top: 0px !important;
    left: 0px !important;
    visibility: visible !important;
  }

  .main-menu .navbar-collapse>.navigation>li>ul>li:first-child,
  .main-menu .navbar-collapse>.navigation>li>ul>li>ul>li:first-child {
    border-top: none !important;
  }

  .main-menu .navbar-collapse>.navigation>li:first-child {
    border: none;
  }

  .main-menu .navbar-collapse>.navigation>li>a,
  .main-menu .navbar-collapse>.navigation>li>ul>li>a,
  .main-menu .navbar-collapse>.navigation>li>ul>li>ul>li>a {
    padding: 12px 20px !important;
    line-height: 22px;
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;
    background: #25262c;
    text-align: left;
  }

  .main-menu .navbar-collapse>.navigation>li>a:hover,
  .main-menu .navbar-collapse>.navigation>li>a:active,
  .main-menu .navigation>li .mega-menu-bar .column>ul>li>a,
  .main-menu .navbar-collapse>.navigation>li>a:focus {
    background: #ff5926;
  }

  .main-menu .navbar-collapse>.navigation>li:hover>a,
  .main-menu .navbar-collapse>.navigation>li>ul>li:hover>a,
  .main-menu .navbar-collapse>.navigation>li>ul>li>ul>li:hover>a,
  .main-menu .navbar-collapse>.navigation>li.current>a,
  .main-menu .navigation>li .mega-menu-bar .column>ul>li>a,
  .main-menu .navbar-collapse>.navigation>li.current-menu-item>a {
    background: #25262c;
    color: #ffffff !important;
  }

  .main-menu .navbar-collapse>.navigation li.dropdown .dropdown-btn {
    display: block;
  }

  .main-menu .navbar-collapse>.navigation li.dropdown:after,
  .main-menu .navigation>li>ul:before {
    display: none !important;
  }

  .header-style-five .search-box-outer {
    display: none;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    border-color: rgba(0,0,0,.1);
    position: absolute;
    top: -48px;
}

  /*=== Mega Menu Style ===*/

  .main-menu .navigation>li.has-mega-menu {
    position: relative !important;
  }

  .main-menu .navigation>li .mega-menu-bar .column {
    padding: 0px;
    margin: 0px;
  }

  .main-menu .navigation>li>.mega-menu {
    position: relative;
    width: 100%;
    padding: 0px !important;
    background: none !important;
    margin: 0px !important;
    left: 0px !important;
    top: 0px !important;
  }

  .main-menu .navigation>li>.mega-menu .row {
    margin: 0px;
    width: 100%;
  }

  .main-menu .navigation>li>.mega-menu:before,
  .main-menu .navigation>li .mega-menu-bar h3 {
    color: #ffffff !important;
    right: 15px;
    font-size: 16px;
    display: none !important;
  }

  .main-menu .navigation>li>.mega-menu,
  .main-menu .navigation>li .mega-menu-bar>ul {
    position: relative;
    border: none;
    float: none;
    visibility: visible;
    opacity: 1;
    display: none;
    margin: 0px;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    padding: 0px;
    outline: none;
    width: 100%;
    background: #2c2c2c;
    -webkit-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    transition: none !important;
    -webkit-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    -moz-transition: none !important;
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .main-menu .navigation>li .mega-menu-bar .column>ul>li {
    border-top: 1px solid rgba(255, 255, 255, 1) !important;
    border-bottom: none;
    opacity: 1 !important;
    top: 0px !important;
    left: 0px !important;
    visibility: visible !important;
  }

  .main-menu .navigation>li .mega-menu-bar .column>ul>li>a {
    padding: 10px 10px !important;
    line-height: 22px;
    color: #ffffff !important;
    background: #25262c;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .main-menu .navigation>li .mega-menu-bar .column>ul>li>a:hover {
    background: #25262c;
  }

  .main-menu .navigation>li>.mega-menu {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  .main-menu .navbar-header .navbar-toggler {
    display: inline-block;
    z-index: 12;
    border: 1px solid #a80382;
    float: none;
    margin: 0px 0px 0px 0px;
    border-radius: 0px;
    background: #a80382;
    position: absolute;
    right: 53px;
    top: -14px;
  }

  .main-header .header-lower .outer-box {
    position: absolute;
    float: none;
    right: 0;
    top: -5px;
    z-index: 99;
  }

 

  .left-side-bar {
    height: auto;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.26rem 5.8rem;
  }

  .form-sell-buy .form-check-inline .form-check-input:before {
    width: 118px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 0px 5rem;
  }

  .bottom-inner-area {
    margin: 0;
  }
  .check {
    top: 1px;
    left: 6px;
}
  .orders-sec .group-price-bock ul.grap-btn li {
    padding: 6px 10px;
  }

  .orders-sec .group-price-bock ul.grap-btn li img {
    width: auto;
  }

  .orders-sec .group-price-bock ul.grap-btn {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .orders-sec .group-price-bock.style-two .table-dark td,
  .orders-sec .group-price-bock.style-two .table-dark th,
  .orders-sec .group-price-bock.style-two .orders-sec .group-price-bock.style-two thead th {
    padding: 2px 6rem 1px;
  }

  .table-area .table-dark td, .table-area .table-dark th, .table-area .table-dark thead th {
      padding: 4px 1rem;
  }
  .orders-sec .group-price-bock.style-two .table-dark td, .orders-sec .group-price-bock.style-two .table-dark th, .orders-sec .group-price-bock.style-two .orders-sec .group-price-bock.style-two thead th {
      padding: 2px 1rem 1px;
  }
  
}

@media only screen and (max-width: 600px) {

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.26rem 4rem;
  }
  .graph-sec .graph-area {
    height: 100%;
}
.table-area .table-dark td, .table-area .table-dark th, .table-area .table-dark thead th {
  padding: 7px 0.6rem;
  width: 42.33%;
}
.balnce-table-sec table {
  width: 770px;
}
.graph-sec article {
  height: 250px !important;
}
.inner-form-block ul.limint-btn {
  padding: 0 15px;
  margin: 0 auto;
  text-align: center;
}
.left-side-bar .nav-tabs {
  border-bottom: none;
  background: #282834;
  padding-left: 15%;
  padding-top: 10px;
  position: relative;
}


  .graph-sec .top-area ul li {
    margin-right: 20px;
  }

  .date-sec ul {
    margin-bottom: 12px;
  }

  .date-sec select.mdb-select.md-form {
    margin-top: 0;
    float: none;
  }

  .form-sell-buy ul.rate-area {
    margin: 14px 0px 11px;
  }

  .form-sell-buy .form-check-inline .form-check-input:before {
    width: 101px;
  }

  .date-sec ul li a {

    padding: 5px 18px;
  }
  .activaty-sec{
    padding: 20px 0;
  }
  .bottom-buy-form.mb-5 {
    margin-bottom: 0 !important;
}
.order-detail .table-area .date-sec tr th {
  padding: 10px 31px;
}
}
@media only screen and (max-width: 600px){
  .table-area .table-dark td, .table-area .table-dark th, .table-area .table-dark thead th {
      padding: 7px 0.6rem;
  }
  .orders-sec .group-price-bock.style-two .table-dark td, .orders-sec .group-price-bock.style-two .table-dark th, .orders-sec .group-price-bock.style-two .orders-sec .group-price-bock.style-two thead th {
      padding: 4px 0.7rem 4px;
  }
}
@media only screen and (max-width: 599px) {

  .nav-tabs .nav-link {
    margin-right: 0 !important;
  }

  .banner-section.style-two .content-box h1 {
    font-size: 32px;
    line-height: 45px;
  }

  .coins-box .coin-inner {
    width: 100%;
    margin-right: 0;
  }

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.26rem 3.2rem;
  }
 
  .form-check-inline {
    margin-right: 13px;
  }

  .right-side-bar .col-xl-6.col-lg-12 {
    padding: 0 15px;
  }

  .bottom-inner-area .col-xl-2.col-lg-3 {
    padding: 0 0;
  }

  .form-sell-buy .form-check-inline .form-check-input:before {
    width: 75px;
  }

  .countery-sec .title-area .text br{
    display: none;
  }
  .main-menu .navbar-header {
    position: relative;
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    padding: 8px 0px;
    right: -35px;
    z-index: 9;
    top: -25px;
}
}
@media only screen and (max-width: 500px){
  .left-side-bar .table td, .left-side-bar .table th {
      padding: 0.26rem 2.7rem;
  }
  .orders-sec {
      position: relative;
      margin-top: 10px;
      height: auto;
      display: block;
  }
  .table-area .table-dark td, .table-area .table-dark th, .table-area .table-dark thead th {
      padding: 7px 2.3rem;
  }
  .orders-sec .group-price-bock.style-two .table-dark td, .orders-sec .group-price-bock.style-two .table-dark th, .orders-sec .group-price-bock.style-two .orders-sec .group-price-bock.style-two thead th {
      padding: 4px 2.6rem 4px;
  }
  .check {
    top: 1px;
    left: 3px;
}
.form-check-inline {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 20%;
}
.graph-sec .top-area .coin-blance-area {
  display: block;
  /* flex-direction: row; */
}
}

@media(min-width: 320px) and (max-width: 374px) {
  .deposit-area .history-deposit{
    width: 238px;
    margin-top: 116px;
  }

  .withdraw-balance .group-form .amount-fees h3{
    white-space: nowrap;
    padding-top: 15px;
  }
}

@media(min-width: 375px) and (max-width: 424px) {
  .deposit-area .history-deposit{
    width: 292px;
    margin-top: 78px;
  }
}
@media(min-width: 425px) and (max-width: 576px) {
  .deposit-area .history-deposit{
    width: 342px;
    margin-top: 78px;
  }
}

@media only screen and (max-width: 479px) {
  .light .balnce-table-sec .balance-table .table td a{
    margin-bottom: 6px;
  }
  .btn-style-one {
    font-size: 11px;
    padding: 3% 11%;
}
.check {
  top: 1px;
  left: 0px;
}

  .left-side-bar .table td, .left-side-bar .table th {
    padding: 0.26rem 1.9rem;
}
  .left-side-bar .table-usdt td,
  .left-side-bar .table-usdt th {
    padding: 0.46rem 2.4rem !important;
  }

  .graph-sec .top-area ul li {
    margin-right: 15px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th,
  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th,
  .orders-sec .group-price-bock.style-two .table-dark td,
  .orders-sec .group-price-bock.style-two .table-dark th,
  .orders-sec .group-price-bock.style-two .orders-sec .group-price-bock.style-two thead th {
    padding: 0px 1.2rem;
  }

  .form-check-inline {
    margin-right: 8px;
  }

  .form-sell-buy .form-check-inline .form-check-input:before {
    width: 60px;
  }
  .deposit-area .nav-deposit li a{
    padding: 18px 39px;
  }
  .deposit-area .nav-deposit li a {
    padding: 9px 26px;
    line-height: 40px;
    font-size: 16px;
}
    .deposit-area .qr-sec .qr-reading {
      position: relative;
      padding: 33px 0 40px;
      text-align: center;
      margin-top: 59px;
  }
  .deposit-area .qr-sec .card-body .top-copy-area .btn{
    float: left;
    margin-top: 10px;
  }
  .deposit-area .deposit-inner{
    padding: 3px 23px 29px 27px;
  }
  .main-footer .footer-area h4 {
    font-size: 18px;
}
}

@media only screen and (max-width: 375px) {

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.26rem 1.8rem;
  }
  .left-side-bar .table td, .left-side-bar .table th {
    padding: 0.26rem 1.7rem;
}
  .left-side-bar .table-usdt td,
  .left-side-bar .table-usdt th {
    padding: 0.46rem 1.9rem !important;
  }
    .countery-sec .title-area .text {
      color: #fff;
      font-size: 16px;
  }
    .sec-title h2 {
      font-size: 21px;
      line-height: 25px;
      margin-bottom: 30px;
  }
  .nav-inner .right-area ul > li {
    float: left;
    margin-left: 20px;
}
.deposit-table .table thead th{
  font-size: 13px;
  white-space: nowrap;
}
.text-white{
  font-size: 14px;
}
.accordion>.card>.card-header .btn-group button{
  font-size: 14px;
}
.deposit-table .table td{
  font-size: 12px;
}
  .deposit-area .nav-deposit li a {
    padding: 9px 22px;
    line-height: 40px;
    font-size: 12px;
  }
  /* .withdraw-balance .group-form button{
    margin-left: 26%;
  } */
  .left-side-bar .table td, .left-side-bar .table th {
    padding: 0.26rem 1.5rem;
}
.graph-sec .top-area .coin-blance-area {
  display: block;
  /* flex-direction: row; */
}
.graph-sec .top-area .coin-blance-area {
  display: block;
  /* flex-direction: row; */
}
.left-side-bar .table td, .left-side-bar .table th {
  padding: 0.26rem 1.3rem;
}
  .table-area .table-dark td, .table-area .table-dark th, .table-area .table-dark thead th, .table-area .table-dark td, .table-area .table-dark th, .table-area .table-dark thead th, .orders-sec .group-price-bock.style-two .table-dark td, .orders-sec .group-price-bock.style-two .table-dark th, .orders-sec .group-price-bock.style-two .orders-sec .group-price-bock.style-two thead th {
    padding: 5px 1.8rem;
  }
}

@media only screen and (max-width: 320px) {

  .left-side-bar .table td,
  .left-side-bar .table th {
    padding: 0.26rem 1.2rem;
  }

  .left-side-bar .table-usdt td,
  .left-side-bar .table-usdt th {
    padding: 0.46rem 1.3rem !important;
  }
}

@media screen and (height: 1069px) {
  .inner-form-block ul.limint-btn {
    padding: 5px 0 15px;
  }

  .form-sell-buy .form-area input[type="text"] {
    padding: 7px 15px;
    margin-bottom: 20px;
  }

  .table-area .table-dark td,
  .table-area .table-dark th,
  .table-area .table-dark thead th {
    padding: 10px .70rem 10px;
  }
}

@media (max-width: 390px){
  .qr-box .qr-area-box{
    height: 171px;
  }
  .steps-google .top-area h5 {
    margin: 25px 0;
      font-size: 15px;
      word-wrap: break-word;
  }
  .google-auth-step {
    position: relative;
    padding: 20px 10px;
}
  
}