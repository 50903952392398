.loader{
  margin-top: 30px;
}

.loader .loader-container {
  margin-top: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}

.loader .loader-container .loader {
  left: 32px;
  position: relative;
  width: 64px !important;
  height: 64px !important;
}

.loader .loader-container .loading-text {
  color: #888;
  font-size: 14px;
  margin-top: -25px;
  font-style: normal;
  font-weight: normal;
}

.dark .loader .loader-container .css-19fmomo {
  background-color: #a80382 !important;
}

.dark .loader .loader-container .css-sjlgy6 {
  background-color: #a80382 !important;
}

.light .loader .loader-container .css-19fmomo {
  background-color:#5c0b49 !important;
}

.light .loader .loader-container .css-sjlgy6 {
  background-color:#5c0b49 !important;
}